@use '~@strategies/tokens/color';

@import 'core';
@import 'vendor';

.logo {
    color: $color_brand;
    font-family: $font_family-primary;
    font-size: $font_size-larger;
    font-weight: 400;

    span {
        font-family: $font_family-primary;
        font-weight: 100;
    }
}

.App {
    .Section {
        padding: $dims_padding-small * 2 $dims_padding-small * 2 $dims_padding-small * 2;

        @include respond-to(tablet) {
            padding: $dims_padding-medium $dims_padding-large;
        }



        @include respond-to(tablet) {
            padding: $dims_padding-medium $dims_padding-large $dims_padding-medium;
        }
        @include respond-to(desktop) {
            padding: $dims_padding-medium $dims_padding-large $dims_padding-medium;
        }



        &:nth-of-type(2n+1) {
            background: $color_bg-light-grey;

            
        }
        
        &.Welcome {
            background: none;
        }
        .content {
            > h3 {
                margin: 0;

                color: $color_black;
                font-size: $font_size-small;
                font-weight: 400;
            }

            h2 {
                margin: $dims_padding-small 0 0;

                color: $color_brand--tertiary;
                font-family: $font_family-primary;
                font-size: $font_size-larger - .36rem;
                font-weight: 600 !important;
            }

          
            p {
                line-height: 1.5em;
                color: $color_black;
                font-size: $font_size-small;
                font-weight: 400;
            }
        }
    }

    button, .button {
        &.with-arrow {
            &:after {
                content: url(./assets/arrow-white.svg);
                display: inline-block;
                vertical-align: middle;
                top: 1px;
                margin-left: $dims_padding-small * 1.5;
            }

            &.blue:after {
                content: url(./assets/arrow-brand.svg);
            }
        }
    }

    footer {
        width: 100%;
        padding: $dims_padding-small $dims_padding-small * 2;
        background: $color_bg-light;

        @include respond-to(tablet) {
            padding: $dims_padding-small $dims_padding-large;
        }

        .logo {
            display: inline-flex;
            align-items: center;
            gap: $dims_padding-small;

            img {
                height: $font_size-medium;

                @include respond-to(tablet) {
                    height: $font_size-larger;
                }
            }

            h3 {
                margin: auto;
                max-width: 7.4em;
                word-wrap: break-word !important;
                white-space: initial !important;
                margin: auto 15px;
                color: #222222;
                font-weight: 400;
                font-size: 1.125rem;
                transition: font-size 0.25s;
                font-family: "Inter", sans-serif;
            }
        }

        button {
            position: absolute;
            right: $dims_padding-medium;
            top: 50%;
            transform: translateY(-50%);

            width: 2rem;
            height: 2rem;
            margin: auto;

            @include respond-to(tablet) {
                width: 3.125rem;
                height: 3.125rem;
            }

            background: $color_brand;
            border: none;
            border-radius: 50%;
            box-shadow: $box_shadow;
            cursor: pointer;

            img {
                width: 70%;
                top: 2px;
                transform: rotate(-90deg);
                transition: top .2s;
            }

            &:hover {
                img {
                    top: -1px;
                }
            }
        }
    }
}

@import 'components/MapIcons/MapIcons';
@import 'components/Events/Events';
@import 'components/Contact/Contact';
@import 'components/ContactForm/ContactForm';
@import 'components/Process/Process';
@import 'components/Resources/Resources';
@import 'components/Vision/Vision';
@import 'components/Welcome/Welcome';
